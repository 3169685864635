<template>
	<el-table height="auto" :data="tableData">
		<el-table-column label="日期" min-width="120">
			<template slot-scope="scope">
				{{scope.row.createdAt|formatDate('YYYY-MM-DD')}}
			</template>
		</el-table-column>
		<el-table-column label="商品id" prop="spu" min-width="120">
		</el-table-column>
		<el-table-column label="站点" prop="channel" min-width="120">
		</el-table-column>
		<el-table-column label="选款规则" prop="rule" min-width="200">
			<template slot-scope="scope">
				{{getRule(scope.row)}}
			</template>
		</el-table-column>
		<el-table-column label="品类" prop="category" min-width="200">
		</el-table-column>
		<el-table-column label="商品主图" min-width="150">
			<template slot-scope="scope">
				<div v-if="scope.row.image" class="table-img-box">
					<el-image :src="scope.row.image" :preview-src-list="[scope.row.image]"></el-image>
				</div>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
	import Data from './mixins/data.js'
	export default {
		mixins: [Data],
		props: {
			tableData: {
				type: Array,
				default: []
			}
		}
	}
</script>

<style>
</style>
