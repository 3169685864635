<template>
	<main-container :showHeader="false">
		<el-card>
			<div class="d-flex flex-row flex-wrap">
				<form-item-sites :abbr="true" :multiple="false" :local="true" v-model="queryForm.channel" :parent="false" @onload="q.channel=queryForm.channel;getList()" :clearable="false" @siteFullName="(v)=>selectedChannel=v"></form-item-sites>
				<form-item label="品类:">
					<el-input v-model="queryForm.category" size="mini"></el-input>
				</form-item>
				<form-item label="选款规则:" style="width:230px;">
					<el-select v-model="queryForm.rule" placeholder="请选择" size="mini" clearable>
						<el-option v-for="item in  recommendRules" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</form-item>
				<form-item label="商品id:" style="width:200px;">
					<el-input v-model="queryForm.spu" placeholder="请输入" size="mini"></el-input>
				</form-item>
				<form-item label="选择日期:" style="width:400px;">
					<el-date-picker v-model="queryForm.date" type="datetimerange" start-placeholder="开始日期"
						end-placeholder="结束日期" value-format="timestamp" style="width:340px;" size="mini" :clearable="false">
					</el-date-picker>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">搜 索</el-button>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini">下 载</el-button>
				</form-item>
				<form-item>
					<upload-button action="/adms_api_v0/google/ads_post/import" accept=".csv" title="上传选款" tooltip="csv文件,表头为'website,spu,category'"></upload-button>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" @click="transitionToPage('google-ads_distincted-list')">去重选款</el-button>
				</form-item>
			</div>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<non-distincted-table :tableData="tableData" class="mt-3 w-100"></non-distincted-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
		</el-card>
	</main-container>
</template>

<script>
	import Moment from 'moment'
	import Page from '@/common/mixins/page.js'
	import Common from '@/common/mixins/common.js'
	import NonDistinctedTable from '@/components/gg-display-ads/non-distincted-table.vue'
	import Config from './mixins/config.js'
	import Data from './mixins/data.js'
	import {
		mapState
	} from 'vuex'
	export default {
		mixins: [Page, Common, Config, Data],
		inject: ['adminLayout'],
		components: {
			NonDistinctedTable
		},
		methods: {
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				const payload = {
					params: {
						...this.page,
						...this.q
					},
					trigger
				}
				return this.$store.dispatch('google-ads/getList', payload)
			}
		}
	}
</script>

