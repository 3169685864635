export default {
	data() {
		return {
			recommendRules: [{
				label: 'Manual',
				value: 'MANUAL'
			}]
		}
	},
	methods: {
		getRule(row) {
			const res = this.recommendRules.find(v => v.value === row.rule)
			if (res) {
				return res.label
			}
		},
	}
}